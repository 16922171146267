import { HELPERS_PAGE_KEY } from 'src/pages/pagesKeys';
import config from '../../../../legacy-app-controller/config';
import HelpersPage from './HelpersPage';
import reducer from './HelpersReducer';

const NODE_ENV = process.env.NODE_ENV || 'development';

export default {
  key: HELPERS_PAGE_KEY,
  path: '/helpers',
  component: HelpersPage,
  elId: 'helpers-page',
  getReduxReducer: (state, action) => reducer(state[HELPERS_PAGE_KEY], action),
  isGranted: () => {
    if (NODE_ENV === 'development' || (config.HELPERS && config.HELPERS.FEATURE_ENABLED)) {
      return true;
    }
    return false;
  },
};

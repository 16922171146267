module.exports = {
  name: "ephj",
  client: "PALEXPO",
  description: "Mobile app for EPHJ 2022",
  validLangs: ["en", "fr", "de"],
  version: {
    mainVersion: "3.0.3",
    buildVersion: {
      iOS: "35",
      android: "34",
      web: "35"
    }
  },
  native: {
    author: "Mobile-Spot",
    backgroundColor: "0xffffff",
    iosBuildTarget: "11.0",
    androidFadeSplashScreenDuration: 750,
    androidTargetSdkVersion: 31,
    androidSigning: {
      keyAlias: "mobile-spot",
      keyStorePassword: "jee1Uu0Hieloh7bi"
    },
    app: "app-react"
  },
  undeliveredFolders: ["source", "exports"],
  crypto: false,
  web: {}
};
var defaultConf = require('./config-default');

var BO_URL = 'ephj2022-data.ms-dev.mobile-spot.com';
var BO_TAIGA_URL = 'https://ephj22.site.calypso-event.net';
var NODE_BACKEND_DOMAIN = 'ephj-node-backend-dev.mobile-spot.com';
var NODE_WS_BAKEND_DOMAIN = 'ephj-node-backend-ws-dev.mobile-spot.com';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: '/home/legacy/palexpo/ephj/ephj2022/ephj2022-data.ms-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'ms-dev.mobilespot.prodleni.local',
  // 'kim1',
  BO_SSH_USER: 'legacy',
  // 'www',
  BO_SSH_PORT: '22',
  //'43122'
  DEPLOY_PATH: '/home/legacy/palexpo/ephj/ephj2022/ephj2022.ms-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-24',
  LOGIN: {// forgottenPasswordURL: `${BO_TAIGA_URL}/mobile/authentification/mot-de-passe-oublie.htm`,
  },
  projectId: '970094533591',
  // FCM sender id
  appId: '9C686-109FC',
  // pushwoosh appid
  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt"),
  // add per environnement
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: false,
    MODE: 'peering',
    // (peering or login) 
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/synchro-favorites")
  },
  REAL_TIME: {
    URL: "https://".concat(NODE_WS_BAKEND_DOMAIN)
  },
  //
  SOCIAL: {
    FEATURE_ENABLED: true,
    TWITTER: {
      POST_PER_PAGE: 10
    },
    // YOUTUBE: {
    //   POST_PER_PAGE: 5,
    // },
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/social")
  }
});